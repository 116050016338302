import { JetView } from "webix-jet";

export default class FilterView extends JetView {
	config() {
		const _ = this.app.getService("locale")._;

		const fieldselect = {
			view: "richselect",
			options: [],
			localId: "field",
			on: {
				onChange: () => this.AdjustToField(),
			},
		};

		const filter = { localId: "filter" };

		const bottombar = {
			type: "clean",
			cols: [
				{
					view: "button",
					value: _("Cancel"),
					width: 100,
					click: () => this.Hide(),
				},
				{},
				{
					view: "button",
					value: _("Apply"),
					width: 100,
					css: "webix_primary",
					click: () => this.ApplyFilter(),
				},
			],
		};

		return {
			padding: 10,
			margin: 6,
			css: "webix_shadow_small",
			rows: [fieldselect, filter, bottombar],
		};
	}

	GetField() {
		const fselect = this.$$("field");
		return fselect.getList().getItem(fselect.getValue());
	}

	AdjustToField() {
		const field = this.GetField();
		this.CreateFilter(
			field.id,
			field.type,
			field.format,
			field.conditions,
			"",
			this.$$("filter")
		);
	}

	CreateFilter(field, type, format, conditions, value, place) {
		const ui = {
			view: "filter",
			localId: "filter",
			conditions,
			field,
			mode: type,
			template: o => {
				let str = o[field];
				const parser =
					o.format || (type == "date" ? webix.i18n.dateFormatStr : null);
				if (parser) str = parser(str);
				return str;
			},
			margin: 6,
		};

		const filter = webix.ui(ui, place);
		const data = this.app.getService("backend").data(field);
		filter.parse(data);
		return filter;
	}

	ApplyFilter() {
		this.app.callEvent("applyFilter", [this.GetValue()]);
	}

	GetValue() {
		const field = this.GetField();
		let rule = {
			...this.$$("filter").getValue(),
			field: field.id,
			type: field.type,
		};

		if (
			rule.condition.filter == "" &&
			(!rule.includes || !rule.includes.length)
		)
			rule = null;

		return rule;
	}
	Hide() {
		this.app.callEvent("applyFilter", [null]);
	}

	Show(value, fields) {
		const fselect = this.$$("field");
		fselect.getList().parse(fields);

		if (value.field) {
			fselect.setValue(value.field);
		} else {
			fselect.setValue(fields[0].id);
		}

		this.$$("filter").setValue(webix.copy(value));
	}
}
