export function query(query, fields) {
	const ops = webix.filters;
	const types = {};
	fields.forEach(a => {
		if (a.conditions)
			a.conditions.forEach(op => {
				if (typeof op === "object") {
					ops[op.id] = op.handler;
				}
			});
		if (a.type) types[a.id] = a.type;
	});

	return queryToFilter(query, ops, types);
}

function queryToFilter(query, ops, types) {
	if (!query || !query.rules.length) return () => true;

	const filters = query.rules.map(a => singleFilter(a, ops, types));
	return (query.glue === "or" ? glueOr : glueAnd)(filters);
}

function glueAnd(f) {
	return function(v) {
		for (var i = 0; i < f.length; i++) {
			if (!f[i](v)) return false;
		}

		return true;
	};
}

function glueOr(f) {
	return function(v) {
		for (var i = 0; i < f.length; i++) {
			if (f[i](v)) return true;
		}

		return false;
	};
}

function singleFilter(rule, ops, types) {
	if (rule.rules) {
		return queryToFilter(rule, ops, types);
	}

	return function(v) {
		const test = v[rule.field];
		if (rule.includes && rule.includes.length) {
			return rule.includes.findIndex(a => a === test) !== -1;
		}

		// sometimes a data field is empty
		if (test) {
			const con = rule.condition.type;
			const filter = ops[con] || ops[types[rule.field]][con];
			return filter(test, rule.condition.filter);
		}

		return false;
	};
}
