export default class Backend {
	constructor(app) {
		this._app = app;
	}

	data(field) {
		let data = this._app.config.data;
		if (typeof data == "function")
			return Promise.resolve(data(field)).then(a =>
				a.map(b => ({ [field]: b }))
			);
		return Promise.resolve(data);
	}

	save(v) {
		console.log(v);
	}
}
