import m0 from "views/actions";
import m1 from "views/bar";
import m2 from "views/base";
import m3 from "views/filter";
import m4 from "views/list";
import m5 from "views/popup";
import { JetView } from "webix-jet";

const views = { JetView };
views["actions"] = m0;
views["bar"] = m1;
views["base"] = m2;
views["filter"] = m3;
views["list"] = m4;
views["popup"] = m5;

export default views;
