import { JetApp, plugins, EmptyRouter } from "webix-jet";
import { createState, link, ignoreInitial } from "jet-restate";

import views from "./export_views";
import en from "locales/en";

// services
import Backend from "./models/Backend";

// api helpers
import { query } from "./helpers/filter";

export class App extends JetApp {
	constructor(config) {
		const state = createState({
			value: config.value || null,
		});

		const defaults = {
			router: EmptyRouter,
			version: VERSION,
			debug: DEBUG,
		};

		super({ ...defaults, ...config });

		this.config.start = config.type === "bar" ? "/bar" : "/list";
		this.config.params = { state, simple: config.simple === true };

		this.setService("backend", new (this.dynamic(Backend))(this));

		this.use(
			plugins.Locale,
			this.config.locale || {
				lang: "en",
				webix: {
					en: "en-US",
				},
			}
		);
	}

	dynamic(obj) {
		return this.config.override ? this.config.override.get(obj) || obj : obj;
	}

	require(type, name) {
		if (type === "jet-views") return views[name];
		else if (type === "jet-locales") return locales[name];

		return null;
	}

	getState() {
		return this.config.params.state;
	}

	GetFilter() {
		return query(this.config.params.state.value, this.config.fields);
	}
}

webix.protoUI(
	{
		name: "query",
		app: App,
		getState() {
			return this.$app.getState();
		},
		getService(name) {
			return this.$app.getService(name);
		},
		$init() {
			const state = this.getState();
			for (let key in state) {
				link(state, this.config, key);
			}

			state.$changes.attachEvent(
				"value",
				ignoreInitial((v, o) => this.callEvent("onChange", v, o))
			);
		},
		getFilterFunction() {
			return this.$app.GetFilter();
		},
	},
	webix.ui.jetapp
);

// re-export for customization
const services = { Backend };
const locales = { en };
export { views, locales, services };
