import { JetView } from "webix-jet";
import FilterView from "./filter";

export default class FilterPopup extends JetView {
	config() {
		return {
			view: "popup",
			point: false,
			body: { $subview: FilterView, name: "body" },
		};
	}
	Show(value, fields, at) {
		// update in webix jet required
		// this.ui must provide a way to wait till UI is fully rendered
		setTimeout(() => {
			this.getRoot().show(at);
			this.getSubView("body").Show(value, fields);
		}, 100);
	}
}
