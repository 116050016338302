import { JetView } from "webix-jet";
import ActionsPopup from "./actions";
import { menuRight, menuClose } from "../helpers/icons";

export default class BaseView extends JetView {
	GetButton() {
		const _ = this.app.getService("locale")._;

		const button = {
			view: "button",
			value: _("Add filter"),
			localId: "button",
			css: "webix_primary",
			click: () => this.Add(null, null),
		};
		return button;
	}

	GetList() {
		const _ = this.app.getService("locale")._;
		const simple = this.getParam("simple");

		const icon = simple ? menuClose : menuRight;

		const AND = _("and");
		const OR = _("or");
		const GLUE = obj => (obj.glue == "and" ? AND : OR);

		const list = {
			view: "tree",
			borderless: true,
			localId: "list",
			glue: "and",
			css: "wbq-query_list",
			scheme: {
				$serialize: function(obj) {
					if (obj.group) {
						return { group: obj.group, glue: obj.glue };
					}

					const out = {
						glue: obj.glue,
						field: obj.field,
						condition: obj.condition,
					};

					if (obj.includes && obj.includes.length) out.includes = obj.includes;

					return out;
				},
				$init: obj => {
					if (obj.group) {
						obj.$css = "group";
						obj.open = true;
					}
				},
			},
			type: {
				template: obj => {
					if (obj.group) {
						return `<div class='wbq-filter_join wbq-filter_join_${
							obj.glue
						}'>${GLUE(obj)}</div>`;
					}

					return (
						this.Template(obj, this.app.config.fields, icon, _) +
						`<div class='wbq-filter_join wbq-filter_join_${obj.glue}'>${GLUE(
							obj
						)}</div>`
					);
				},
			},
			onClick: {
				"action-menu": function(e, id) {
					this.$scope.Actions.Show(e.target, id);
				},
				"action-close": (e, id) => {
					this.Delete(id);
				},
				"wbq-filter_join": (e, id) => {
					this.SwitchGlue(id);
				},
			},
			on: {
				onItemDblClick: id => this.EditStart(id),
			},
		};

		return list;
	}

	init() {
		this.Actions = this.ui(ActionsPopup);

		this.on(this.app, "applyFilter", v => this.EditStop(v));

		this.on(this.app, "action", (type, id) => {
			if (type === "delete") {
				this.Delete(id);
			} else if (type === "add-filter") {
				this.Add(null, id);
			} else if (type === "add-group") {
				this.AddGroup(null, id);
			} else if (type === "edit") {
				webix.delay(() => this.EditStart(id));
			}
		});

		this.State = this.getParam("state");
		this.on(this.State.$changes, "value", v => {
			this.LastValue = JSON.stringify(v);
			this.$$("list").clearAll();
			if (v) {
				this.$$("list").parse(this.ConvertTo(v));
			}
		});
	}

	ConvertTo(v, glue) {
		if (v.rules) {
			const data = v.rules.map(a => this.ConvertTo(a, v.glue));
			if (!glue) return data;
			return { group: true, glue, data };
		}

		const out = { ...v, glue };
		return out;
	}

	ConvertFrom(id, source) {
		let glue = "and";
		const branch = source.getBranch(id);
		const rules = branch.map(({ id, field, type, condition, includes }) => {
			if (field) return { field, type, condition, includes };
			return this.ConvertFrom(id, source);
		});

		if (!rules.length) return null;

		glue = branch[0].glue;
		return { glue, rules };
	}

	Add(obj, after) {
		this.EditStop();

		obj = obj || { field: "" };
		const list = this.$$("list");
		const parent = after ? list.getParentId(after) : 0;
		const index = after ? list.getBranchIndex(after) + 1 : -1;
		if (!after && list.count()) after = list.getFirstId();
		const glue = after ? list.getItem(after).glue : list.config.glue;

		obj.glue = glue;
		const id = list.add(obj, index, parent);
		this.EditStart(id);
		this.CreateMode = true;
	}

	SwitchGlue(id) {
		const list = this.$$("list");
		const branch = list.data.getBranch(list.getParentId(id));
		for (let i = 0; i < branch.length; i++) {
			list.updateItem(branch[i].id, {
				glue: branch[i].glue == "and" ? "or" : "and",
			});
		}
		this.Save();
	}

	AddGroup(obj, after) {
		this.EditStop();

		obj = obj || {};
		const list = this.$$("list");
		const parent = after ? list.getParentId(after) : 0;
		const index = after ? list.getBranchIndex(after) + 1 : -1;
		const node = { id: webix.uid(), group: true };

		const glue = after ? list.getItem(after).glue : list.config.glue;
		node.glue = glue;
		obj.glue = node.glue == "and" ? "or" : "and";

		list.add(node, index, parent);
		const id = list.add(obj, null, node.id);
		this.EditStart(id);
		this.CreateMode = true;
	}

	Delete(id) {
		this.EditStop();
		this.DeleteSilent(id);
		this.Save();
	}

	DeleteSilent(id) {
		const list = this.$$("list");
		let parent = list.getParentId(id);
		list.remove(id);

		const branch = list.data.branch[parent];
		if (parent && !branch) {
			// empty group, removing
			this.DeleteSilent(parent);
		} else if (branch.length == 1 && list.getItem(branch[0]).group) {
			// we have a single subgroup without ability to add a new item on the same level
			// remove extra layer
			const sub = branch[0];
			if (parent) {
				const grand = list.getParentId(parent);
				const pIndex = list.getBranchIndex(parent, grand);
				list.move(sub, pIndex, list, { parent: grand });
				this.DeleteSilent(parent);
			} else {
				// top level group
				let pIndex = list.getBranchIndex(sub, 0);
				const kids = [].concat(list.data.branch[sub]);
				list.move(kids, pIndex, list, { parent: 0 });
				this.DeleteSilent(sub);
			}
		}
	}

	EditStop(v) {
		if (!this.Active) return;

		const list = this.$$("list");
		if (!v) {
			if (this.CreateMode) this.DeleteSilent(this.ActiveId);
			else list.refresh(this.ActiveId);
		} else {
			v = v || this.Active.GetValue();
			v.includes = v.includes || [];
			list.updateItem(this.ActiveId, { ...v });
		}

		var t = this.Active;
		this.Active = null;
		t.destructor();

		this.Save();
	}

	Save() {
		const v = this.ConvertFrom(0, this.$$("list").data);

		// ensure that something really changed in the config
		const asString = JSON.stringify(v);
		if (this.LastValue !== asString) {
			this.State.value = v;
		}
	}

	Template(obj, fields, icon, _) {
		let op = "&nbsp;";
		const field = fields.find(a => a.id === obj.field) || { value: "" };

		if (obj.includes && obj.includes.length) {
			op = _("in") + " ";
			for (var i = 0; i < obj.includes.length; i++) {
				op +=
					(i > 0 ? ", " : "") +
					`<span class='wbq-field-value'>${obj.includes[i]}</span>`;
			}
		} else {
			if (obj.condition && obj.condition.type) {
				op = `${_(
					obj.condition.type
				)} <span class='wbq-field-value'>${this.Format(
					obj.condition.filter,
					field.format,
					field.type
				)}</span>`;
			}
		}

		return `<span class='wbq-field-box'><span class='wbq-field-name'>${field.value}</span> ${op} </span>${icon}`;
	}

	Format(value, format, type) {
		if (!value) return "";
		if (value.start)
			value =
				this.Format(value.start, format, type) +
				(value.end ? ` - ${this.Format(value.end, format, type)}` : "");
		else {
			const parser =
				format || (type == "date" ? webix.i18n.dateFormatStr : null);
			if (parser) value = parser(value);
		}
		return value;
	}
}
