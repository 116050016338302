import TopView from "./base";
import FilterPopup from "./popup";

export default class TopBarView extends TopView {
	config() {
		const button = this.GetButton();
		button.width = 100;

		const list = this.GetList();
		list.scroll = this.app.config.scroll !== false ? "x" : "";
		list.borderless = true;
		list.css = "wbq-query_bar";

		const small = webix.skin.$name == "mini" || webix.skin.$name == "compact";
		let padding = 8,
			height = 58;
		if (small) {
			padding = 4;
			height = 50;
		}

		return {
			view: "toolbar",
			paddingX: padding * 2,
			paddingY: padding,
			height: height,
			borderless: true,
			cols: [list, button],
		};
	}

	EditStart(id) {
		this.EditStop();

		const list = this.$$("list");

		this.ActiveId = id;
		this.Active = this.ui(FilterPopup);
		this.Active.getRoot().attachEvent("onHide", () => {
			if (this.Active) this.EditStop();
		});

		const at = webix.html.offset(list.getItemNode(id));
		this.Active.Show(list.getItem(id), this.app.config.fields, at);
		this.CreateMode = false;
	}
}
