export default {
	"Add filter": "Add filter",

	in: "=",
	equal: "=",
	notEqual: "<>",
	less: "<",
	greater: ">",
	greaterOrEqual: ">=",
	lessOrEqual: "<=",
	contains: "contains",
	notContains: "not contains",
	beginsWith: "begins",
	notBeginsWith: "not begings",
	endsWith: "ends",
	notEndsWith: "not ends",
	between: "between",
	notBetween: "not between",

	and: "and",
	or: "or",
	Edit: "Edit",
	"Add Filter": "Add Filter",
	"Add Group": "Add Group",
	Delete: "Delete",
	Apply: "Apply",
	Cancel: "Cancel",
};
