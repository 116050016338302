import { JetView } from "webix-jet";

export default class ActionsPopupView extends JetView {
	config() {
		const _ = this.app.getService("locale")._;

		return {
			view: "popup",
			point: false,
			body: {
				view: "list",
				yCount: 4,
				width: 120,
				borderless: true,
				data: [
					{ id: "edit", value: _("Edit") },
					{ id: "add-filter", value: _("Add Filter") },
					{ id: "add-group", value: _("Add Group") },
					{ id: "delete", value: _("Delete") },
				],
				click: id => {
					this.app.callEvent("action", [id, this.Item]);
					this.Hide();
				},
			},
		};
	}

	Hide() {
		this.getRoot().hide();
	}

	Show(node, item) {
		this.Item = item;
		const pos = webix.html.offset(node);
		this.getRoot().show({
			x: pos.x + pos.width + webix.skin.$active.dataPadding,
			y: pos.y - 10,
		});
	}
}
