import BaseView from "./base";
import FilterView from "./filter";

export default class TopListView extends BaseView {
	config() {
		return {
			paddingX: 16,
			paddingY: 8,
			margin: 0,
			rows: [this.GetButton(), this.GetList()],
		};
	}

	EditStart(id) {
		if (this.ActiveId && this.ActiveId == id) return;

		this.EditStop();

		const list = this.$$("list");
		const container = list.getItemNode(id);
		container.classList.add("active-editor");
		container.innerHTML = "";

		this.ActiveId = id;
		this.Active = this.ui(FilterView, { container });
		this.$$("list").attachEvent("onAfterRender", () => this.EditStop());

		this.Active.Show(list.getItem(id), this.app.config.fields);
		this.CreateMode = false;
	}
}
